export const SESSION_EXPIRESIN = 1800


//original, hardcoded
// export const createConfig = () => {
//     var isProd = true

//     if (isProd) {
//         return {
//             isProd: true,
//         }
//     }

//     return {
//         isProd: false,
//     }
// }




// //alternative using URL, not needed currently
// export const createConfig = () => {
//     var url = window.location.href

//     if(url.includes("localhost"))
//         {
//             console.log("localhost override")
//             return {
//                 isProd: true,
//             }
//         }

//     console.log("url is: " + url)
//     if(url.includes("myvlomni.com"))
//     {
//         console.log("chose AwsProd")
//         return {
//             isProd: true,
//         }
//     }
//     else
//     {
//         console.log("chose AwsDev")
//         return {        
//             isProd: false,
//         }
//     }    
// }


export const createConfig = () => {
    var awsEnvironment = process.env.REACT_APP_ENV

    // console.log("REACT_APP_ENV is: " + process.env.REACT_APP_ENV)
    if (awsEnvironment == undefined) {
        console.log("Env var not set, defaulting to AwsProd.")
        awsEnvironment = "PROD"
    }

    if (awsEnvironment == "PROD") {
        console.log("chose AwsProd")
        return {
            isProd: true,
        }
    }
    else {
        console.log("chose AwsDev")
        return {
            isProd: false,
        }
    }
}

export const createConfigRegion = () => {
    var awsRegion = process.env.REACT_APP_REGION

    // console.log("REACT_APP_REGION is: " + process.env.REACT_APP_REGION)

    if (awsRegion == "us-east-1") {
        console.log("chose US")
        return {
            awsRegion: "us-east-1",
        }
    }
    else if (awsRegion == "eu-west-1") {
        console.log("chose EU")
        return {
            awsRegion: "eu-west-1",
        }
    }
    else {
        console.log("region var not set, defaulting to US.")
        return {
            awsRegion: "us-east-1",
        }
    }
}

export const limit = 500
export const statsBarLimit = 2250

export const default_userAvatar = "https://customerui-profile-icons.s3.us-east-1.amazonaws.com/eHS-SymbolOnly-Black.png"

export const prodEndPoints = {
    'us-east-1': {
        apiurl: "https://ndgq84taa0.execute-api.us-east-1.amazonaws.com/prod",
        bucketurl: "customerui-prod",
        bucketname: "vlomni-prod"
    },
    'ca-central-1': {
        //on dev account
        apiurl: "https://jxhkdgxkka.execute-api.ca-central-1.amazonaws.com/api",
        bucketurl: "customerui-ca",
        bucketname: "vlomni-dev-ca"
    },
    'eu-west-1': {
        apiurl: "https://9jp9g7227c.execute-api.eu-west-1.amazonaws.com/prod/",
        bucketurl: "customerui-prod-ir",
        bucketname: "vlomni-prod-ir"
    },
}

export const devEndPoints = {
    'us-east-1': {
        apiurl: "https://hlgmbcgbnd.execute-api.us-east-1.amazonaws.com/api",
        bucketurl: "customerui",
        bucketname: "vlomni-dev"
    },
    'ca-central-1': {
        //on dev account
        apiurl: "https://jxhkdgxkka.execute-api.ca-central-1.amazonaws.com/api",
        bucketurl: "customerui-ca",
        bucketname: "vlomni-dev-ca"
    },
    'us-west-2': {
        apiurl: "https://yj44alu1qc.execute-api.us-west-2.amazonaws.com/api",
        bucketurl: "customerui",
        bucketname: "vlomni-dev-or"
    },
}

export const marketPlaceID = {
    'Canada': "A2EUQ1WTGCTBG2",
    'US': "ATVPDKIKX0DER",
    'Mexico': "A1AM78C64UM0Y8",
    'Brazil': "A2Q3Y263D00KWC",
    'UK': "A1F83G8C2ARO7P"
}

export const sellerCentralURL = {
    'Canada': "https://sellercentral.amazon.ca",
    //on dev account
    'US': "https://sellercentral.amazon.com",
    'Mexico': "https://sellercentral.amazon.com.mx",
    'Brazil': "https://sellercentral.amazon.com.br",
    'UK': "https://sellercentral-europe.amazon.com",
}

export const vendorCentralURL = {
    'Canada': "https://vendorcentral.amazon.ca",
    //on dev account
    'US': "https://vendorcentral.amazon.com",
    'Mexico': "https://vendorcentral.amazon.com.mx",
    'Brazil': "https://vendorcentral.amazon.com.br",
    'UK': "https://vendorcentral.amazon.co.uk"
}

export const marketplaceRegion = {
    "UK": ["ANTLER_UK"],
    "Canada": ["NEMCOR", "ecscoffee.myshopify.com"]
}

export const amazon_app_id = 'amzn1.sp.solution.04032b85-e19b-4316-9288-49411ee7b3ee'
export const amazon_client_id = 'amzn1.application-oa2-client.e73f8974b5f449269aa9cdff1fabb8e5'
export const amazon_client_secret = 'amzn1.oa2-cs.v1.08d2561d1eddc54720b32d1f14947f7bcd53f9ef74a36bbb59667cb638261aee'
// export const amazon_app_id = 'amzn1.sp.solution.63fab2fa-81ce-4fc7-8bcd-2eac1fb2d715'

export const omni_trust_center = "https://www.ehousestudio.com/page/privacy-policy/"
export const defaultVLLogo = "https://customerui.s3.amazonaws.com/Resources/Images/NavLogo.jpg"
export const defaultEHouseLogo = "https://customerui-profile-icons.s3.amazonaws.com/eHS-SymbolOnly-Black.png"

export const vlomni_logo_connectors = ["PLD_GEN"]